<template>
    <div>
        <el-card style="height: 89vh">
          <el-row :gutter="20">
            <el-col :span="21">
              <baidu-map :center="map.center" style="height: calc(100vh - 120px);"
                         :scroll-wheel-zoom="true"
                         :zoom="map.zoom"
                         :max-zoom="18" :min-zoom="10"
                         :mapStyle="mapStyle"
                         @moveend="mapMoveEnd"
                         class="baidumap">
                <template v-for="(it,index) in ms">
                  <bm-label :content="it.shortname" :position="it.position" :offset="{width: -it.tl*6, height: -48}"
                            :labelStyle="{color: 'red', fontSize : '12px', border: '2px solid orange', borderRadius: '8px', backgroundColor: 'orange'}"
                            :title="it.name"/>
                  <bm-marker
                      :key="it.id"
                      :position="it.position"
                      @click="markclick(it,index)"
                      @mouseover="markover(it,index)"
                      @mouseout="markout(it,index)"><!--                      :icon="it.if? iocn:newincon"-->
                    <bm-info-window @close="infoWindowClose" @open="infoWindowOpen"
                                    :show="it.show"
                                    :title="it.name"
                                    :position="it.position">
                      <div style="color:#000;font-size:16px;">
                        <div style="margin-top:5px;height: 2px; width:100%;background-color: #1981E1 !important;"></div>
                        <el-card v-if="!clickFlag" style="color:#000;font-size:12px; background: tan;" body-style="padding: 5px">
                          <div style="margin-top:0px;width:220px">站号：{{ it.number }}</div>
                          <div style="margin-top:5px;width:220px">站名：{{ it.name }}</div>
                          <div style="margin-top:5px;width:220px">地址：{{ it.address }}</div>
                          <div style="margin-top:5px;width:220px">经纬度：{{ it.longitude2 + '/' + it.latitude2}}</div>
                          <div style="margin-top:5px;width:220px">海拔高度：{{ it.altitude }}米</div>
                          <div style="margin-top:5px;width:220px">台站类型：{{ it.detailedtype}}</div>
                          <div style="margin-top:5px;width:220px">建站时间：{{ it.createstationtime }}</div>
                        </el-card>
                        <el-card v-else style="color:#000;font-size:12px;; background: tan;" body-style="padding: 5px">
                          <div v-for="item in list" :key="item.id" class="openbox" @click="openbox(item)">{{ item.name }}</div>
                          <div style="display: flex; justify-content: flex-end">
                            <el-button type="success" size="mini" @click="closeInfo">关闭</el-button>
                            <el-button type="primary" size="mini" @click="closeInfo2">图集</el-button>
                            <el-button type="info" size="mini" @click="closeInfo3">信息</el-button>
                          </div>
                          <div hidden1  @click="checkImage1">
                          </div>
                        </el-card>
                      </div>
                    </bm-info-window>

                    <bm-context-menu>
                      <bm-context-menu-item :callback="uploadImg" text="上传台站图片"></bm-context-menu-item>
                    </bm-context-menu>
                  </bm-marker>
                </template>
              </baidu-map>
            </el-col>
            <el-col :span="3">
              <div>
                <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
              </div>
            </el-col>
          </el-row>
        </el-card>

<!--装备详情弹窗-->
      <el-dialog  :visible.sync="dialogTableVisible" width="380px" top="1vh" @opened="dialogOpen">
        <template slot="title">
          <div style="font-weight: 600; font-size: 24px; color: #2A60C9; "><i class="el-icon-menu"></i><span>装备详情</span></div>

        </template>
        <template slot="footer">
          尾巴~~~~~
        </template>
<!--                <el-card style="padding: 0px !important;margin: 0 !important;background: #7fa0df">-->

        <table  border="1" align="center">
          <tr>
            <td width="100">二维码</td><td><div id="qrcode" ref="qrcode" /></td>
          </tr>
          <tr><td>备件名称</td><td>{{list1.name}}</td></tr>
          <tr><td>生产商</td><td>{{list1.manufacturer}}</td></tr>
          <tr><td>状态</td><td>{{list1.status}}</td></tr>
          <tr><td>检定有效期</td><td>{{list1.validperiod}}</td></tr>
        </table>
<!--                </el-card>-->
      </el-dialog>

      <el-dialog title="台站图片集" :visible.sync="dialogFormVisible">
<!--        <div class="demo-image__lazy">-->
<!--          <div style="display: flex;">-->
<!--            <div class="demo-image__preview" @click="checkImage">-->
<!--            <el-image v-for="url in urls" :key="url" :src="url" lazy>-->
<!--            </el-image>-->
<!--            </div>-->
                  <div class="cell-item zxzx demo-image__preview"  v-for="(item,index) in urls" :key="index" @click="checkImage1" style="padding: 0 1vw;">
                    <el-image  id="myButton1"
                        style="width: 100%; margin-top: 2vh"
                        :src="item"
                        :preview-src-list="[item]">
                    </el-image>
                    <p style="background-color: rgba(0,0,0,0.1); color: #fff; text-align: center;  margin-top: -6px;">{{item.createTime}}</p>
                  </div>
<!--          <el-upload-->
<!--              active="#"-->
<!--              list-type="picture-card"-->
<!--              :on-success="handleAvatarSuccess"-->
<!--              :on-preview="handlePictureCardPreview"-->
<!--              :on-remove="handleRemove">-->
<!--            <i class="el-icon-plus"></i>-->
<!--          </el-upload>-->
<!--        </div>-->
<!--        </div>-->
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </div>
      </el-dialog>
    </div>



</template>

<script>
import QRCode from 'qrcode2'
    //引入组件
    import {
        BaiduMap,
        BmControl,
        BmView,
        BmAutoComplete,
        BmLocalSearch,
        BmMarker,
        BmGeolocation,
    } from "vue-baidu-map";
    export default {
        data() {

            return {
              urls: [
                'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
                'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
                'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
                'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
                'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
                'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
                'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
              ],
              data: [],
              defaultProps: {
                children: 'children',
                label: 'label'
              },
              url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
              srcList: [
                'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
              ],
              qrUrl: location.href, // 本页面地址
              qrcode: null,
              dialogTableVisible: false,
              dialogFormVisible: false,
              stations: [],
              lists: [],
              list: [],
              list1: {},
              clickFlag: false,
              // iocn: {url: 'http://developer.baidu.com/map/jsdemo/img/fox.gif', size: {width: 300, height: 157}},
              // newincon: {url: 'http://www.yz-awen.cn:9090/files/1697438073596-avatar.png', size: {width: 300, height: 157}},
              markerNumber: 0,
              currentMarker: 0,
              //定位位置信息
              center: {lng: 108.5, lat: 24.5},
              //检索关键字
              keyword: "",
              //输入框input值
              input3: "",
              markerIcon: "", //标注图片
              isShowInfo: true,
              map: {
                  center: {lng: 108.5, lat: 24.5},
                  zoom: 11,
              },
              mapStyle: {
                  styleJson: [
                      {
                          "featureType": "all",
                          "elementType": "geometry",
                          "stylers": {
                              "hue": "#007fff",
                              "saturation": 89
                          }
                      },{
                          "featureType": "water",
                          "elementType": "all",
                          "stylers": {
                              "color": "#ffffff"
                          }
                      }
                  ]
              },
              ms:[ ]
            };
        },
        //需要引入的组件
        components: {
            BaiduMap,
            BmControl,
            BmView,
            BmAutoComplete,
            BmLocalSearch,
            BmMarker,
            BmGeolocation,
        },
        mounted(){  //读取站点和装备信息
            this.$request.post('/yz/getStationLists', 'this.form').then(res => {
                if (res.code === '200') {
                  this.stations=res.data
                  var arr=this.ms
                  var tree=[]
                  var treeData=this.data
                  this.stations.forEach(function(value,index,array){
                    if(value.shortname.length<4){
                      tree.push({ label: value.shortname})
                    }
                    arr.push({
                      id: index,
                      show: false,
                      number: value.number,
                      name: value.name,
                      address: value.address,
                      position: {lng: value.longitude1, lat: value.latitude1},
                      longitude2: value.longitude2,
                      latitude2: value.latitude2,
                      altitude: value.altitude,
                      detailedtype: value.detailedtype,
                      createstationtime: value.createstationtime,
                      shortname: value.shortname,
                    tl: value.shortname.length,
                    })
                  });
                  tree.forEach(function(value1,index1,array1){
                    var childrenArr=[]
                    res.data.forEach(function(value2,index2,array2){
                      // console.log(value2.shortname.indexOf(value1.label))
                        if(value2.shortname.indexOf(value1.label)===0){
                          console.log("子节点：",value2.shortname)
                          childrenArr.push({ label: value2.shortname, id: index2, lng:value2.longitude1, lat:value2.latitude1})
                        }
                    });

                    treeData.push({
                      label: value1.label,
                      children: childrenArr
                    })
                  });
                  console.log('###站点列表：', this.ms)
                } else {
                    this.$message.error(res.msg)
                }
            })
          //
          this.$request.post('/yz/getEquipmentsLists', 'this.form').then(res => {
                if (res.code === '200') {
                  var lists=[]
                  this.stations.forEach((item1,i,array)=> {
                    var arr=[]
                    res.data.forEach((item2,j,array)=> {
                      if(item2.currentlocation.includes(item1.name)){
                        arr.push(item2)
                      }
                    })
                    // console.log(item1.name,arr)
                    lists.push(arr)
                  })
                    this.lists=lists
                  // console.log(this.lists)
                }
          })

          window.cl = this.cl
        },
        methods: {
          cl() {
            let srcUrl=document.querySelector('.el-image-viewer__img').src;
            this.downloadIamge(srcUrl,srcUrl)
          },
          checkImage(){//这个事件要绑定el-image父级盒子上
            console.log('点击事件');
            let a=document.querySelector('.el-image-viewer__actions__inner');
            // $(a).append(`<i class="el-icon-download" onclick="installImage()"></i>`)
            // a.append( `<i class="el-icon-download" onclick="cl()"></i>`)
            console.log(a)
            let ff = document.createElement('i')
            ff.innerHTML = `<i class="el-icon-download" onclick="cl()"></i>`
            a.appendChild(ff)
          },
          downloadIamge(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
              let canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.height;
              let context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, image.width, image.height);
              let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
              let a = document.createElement("a"); // 生成一个a元素
              let event = new MouseEvent("click"); // 创建一个单击事件
              a.download = name || "photo"; // 设置图片名称
              a.href = url; // 将生成的URL设置为a.href属性
              a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
          },
          closeInfo2(){
            // 模拟点击事件
            // const button = document.getElementById('myButton');
            // button.click();
            this.dialogFormVisible=true
          },
            closeInfo(){
                this.ms[this.markerNumber].show=false;
              this.clickFlag=false
            },
            markclick(it,index){
                this.markerNumber=index;
                this.ms[index].show=true;
                // this.ms[index].mess="true";
              this.clickFlag=true
              this.list=this.lists[this.markerNumber]
              console.log("点击了标记："+this.clickFlag+","+this.list[0].name)
            },
          openbox(item){
            this.dialogTableVisible=true
            this.list1=item
          },
          dialogOpen(){
            if (this.qrcode != null) {
              // this.$refs.qrcode.innerHTML = ''; //这个也可以清除原二维码
              this.qrcode.clear(); // 清除原来的二维码, 这里是动态生成DIV， 所以this.qrcode
              this.qrcode.makeCode(this.list1.qrcode)
            }else {
              this.qrcode = new QRCode('qrcode', {
                typeNumber: 4,
                width: 100, // 设置宽度，单位像素
                height: 100, // 设置高度，单位像素
                colorDark: 'red', //二维码前景色
                colorLight: '#fff', //二维码背景色
                text: this.list1.qrcode // 设置二维码内容或跳转地址
              })
            }
          },
            markover(it,index){
              console.log("在标记上面："+this.clickFlag +","+index)
              if(!this.clickFlag) {
                this.currentMarker=index
                this.ms[index].show=true;
              }
            },
            markout(it,index){
                console.log("标记上移走："+this.clickFlag+","+index)
              if(!this.clickFlag) this.ms[index].show=false;
            },
            mapMoveEnd(){ // 地图拖动结束时
                // this.ms[this.markerNumber].show=false;
            },
            infoWindowClose () {
                // this.show = false
              this.clickFlag=false
            },
            infoWindowOpen () {
                // this.show = true
              // this.clickFlag=true
            },
            //输入框
            inputfz() {
                this.keyword = this.input3;
            },
            //地图加载后的回调
            mapReady({ BMap, map }) {
                //保存this指向，因为在百度的回调中this不指向vue
                const _this = this;
                // 获取自动定位方法
                var geolocation = new BMap.Geolocation();
                // 获取自动定位获取的坐标信息
                geolocation.getCurrentPosition(
                    function (r) {
                        //可以conso.log看一下这个r，他里面包含了检索到的位置信息。下面就把两个维度信息赋值给center来定位
                        _this.center = {
                            lng: r.point.lng,
                            lat: r.point.lat,
                        };
                    },
                    //启用高精度
                    { enableHighAccuracy: true }
                );
            },
          handleNodeClick(data){
            console.log(data);
            if(data.id) {//alert(data.id)
              this.map={center: {lng: data.lng, lat: data.lat}, zoom: 11}
              console.log(this.map)
              this.markerNumber=data.id;
              this.ms[data.id].show=true;
              // this.ms[index].mess="true";
              this.clickFlag=true
              this.list=this.lists[this.markerNumber]
            }
          },
          uploadImg (e) {
            if(!this.clickFlag) {
              //alert(this.currentMarker) //`${e.point.lng} / ${e.point.lat}`)
              //console.log(this.currentMarker)
              this.dialogFormVisible=true
            }
          },
          handleAvatarSuccess(response, file, fileList) {
            // 把头像属性换成上传的图片的链接
            alert('上传成功！')
            //this.form.avatar = response.data
          },
          checkImage11(){//这个事件要绑定el-image父级盒子上
            alert('点击事件');


              // let element = document.getElementsByClassName('el-image-viewer__actions__inner')
              // element.innerHTML = `${this.list.map(item => `<i class="el-icon-download" onclick="installImage()"></i>`).join('')}`


            // let a=document.querySelector('.el-image-viewer__actions__inner');
            // $(a).append(`<i class="el-icon-download" onclick="installImage()"></i>`)
          },
          downloadIamge11(imgsrc, name) {//下载图片地址和图片名
            let image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
              let canvas = document.createElement("canvas");
              canvas.width = image.width;
              canvas.height = image.height;
              let context = canvas.getContext("2d");
              context.drawImage(image, 0, 0, image.width, image.height);
              let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
              let a = document.createElement("a"); // 生成一个a元素
              let event = new MouseEvent("click"); // 创建一个单击事件
              a.download = name || "photo"; // 设置图片名称
              a.href = url; // 将生成的URL设置为a.href属性
              a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
          },

        },
    };
</script>

<style scoped>
    /* 给个宽高 */
    .bm-view {
        height: 800px;
        width: 100%;
    }
    .baidumap {
        height: 800px;
        width: 100%;
    }
    .input-with-select {
        width: 400px;
        margin-bottom: 5px;
    }
    .search {
        height: 300px;
        overflow: auto;
    }
    .openbox{
      margin: 5px;
      padding: 5px;
      background: #8c939d;
      font-size:12px;
      border-radius: 5px;
    }
    .openbox:hover{
      background: #6e809a;
      font-weight: 600;
    }
    .left{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      height: 110px;
      border: 2px solid #000;
    }
    .right{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      height: 110px;
      border: 2px solid #000;
    }
    .left1{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      /*height: 110px;*/
      border: 2px solid #000;
    }
    .right1{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70%;
      /*height: 110px;*/
      border: 2px solid #000;
    }
    th, td {
      border: 1px solid black; /* 设置边框 */
      padding: 5px; /* 设置内边距 */
      text-align: center; /* 设置文本对齐方式 */
    }
    .el-dialog__header {
      margin: 100px !important;
      background: #2A60C9 !important;
    }
    .el-dialog__body {
      padding: 100px !important;
      background: #2A60C9 !important;
    }
    .el-dialog__footer {
      background: #2A60C9 !important;
    }
</style>
